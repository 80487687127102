<template>
  <appDialog
    btnText="账单导出"
    title="账单导出"
    width="500"
    @before-open="form = {}"
    @submit="onsubmit"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <baseForm1 v-model="form" :option="option" style="width: 400px"></baseForm1>
  </appDialog>
</template>

<script>
import { balanceRecordexport } from '@/api/financial/userbalance'
import { exportWrap } from '@/utils'

export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {},
      option: {
        column: [
          {
            label: '日期',
            prop: 'tradeTime',
            type: 'daterange'
          }
        ]
      }
    }
  },
  methods: {
    async onsubmit(form, done) {
      const tradeTime = this.form.tradeTime || []

      const defaultParams = {
        userNature: this.form.userNature,
        idList: this.data.map(({ id }) => id)
      }

      const params = tradeTime[0]
        ? Object.assign(defaultParams, {
            startTradeTime: `${tradeTime[0]} 00:00:00`,
            endTradeTime: `${tradeTime[1]} 23:59:59`
          })
        : defaultParams
      const res = await exportWrap(balanceRecordexport(params), '用户余额账单')
      done(res && undefined)
    }
  }
}
</script>

<style lang="scss">
</style>
