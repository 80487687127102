
import request from '@/service/request'
import { getXMenuType } from '@/utils/constant'

// 列表
export function userList(data={},menuType='menu') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/financeService/paymentAccount/userList',
    method: 'post',
    data
  })
}
// 导出账单
export function balanceRecordexport(data={},menuType='btn') {
  return request({
    headers: getXMenuType(menuType),
    url: '/externaladmin/financeService/balanceRecord/export',
    method: 'post',
    data,
    responseType:'blob'
  })
}
